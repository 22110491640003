import { Component, Provide, Vue } from "vue-property-decorator";
import { Banner, Nav, OfficialWebsite, Tab } from "@/interface/pagas";
import { gsap, TweenMax } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
@Component({
  components: {},
})
export default class Home extends Vue {
  @Provide() bannerList: Array<Banner> = [
    { id: 1, imgUrl: require("@/assets/image/home/home_banner_0.jpg") },
    { id: 2, imgUrl: require("@/assets/image/home/home_banner_1.jpg") },
    { id: 3, imgUrl: require("@/assets/image/home/home_banner_2.jpg") },
    { id: 4, imgUrl: require("@/assets/image/home/home_banner_3.jpg") },
    { id: 5, imgUrl: require("@/assets/image/home/home_banner_4.jpg") },
    { id: 6, imgUrl: require("@/assets/image/home/home_banner_5.jpg") },
  ];
  @Provide() bannerIndex: number = 0;

  @Provide() navList: Array<Nav> = [
    {
      id: 1,
      link: "embroideryHome",
      name: "储绣家居",
      remarks: "EMBROIDERY HOME",
      imgUrl: require("@/assets/image/home/menu_0.jpg"),
      openLink: false,
    },
    {
      id: 2,
      link: "warmLife",
      name: "丝秘花园",
      remarks: "SIM GARDEN",
      imgUrl: require("@/assets/image/home/menu_1.jpg"),
      openLink: false,
    },
    {
      id: 3,
      link: "guide",
      name: "高级定制",
      remarks: "CUSTOMIZATION",
      imgUrl: require("@/assets/image/home/menu_2.jpg"),
      openLink: true,
    },
  ];

  @Provide() gifShow: boolean = false;

  @Provide() officialWebsiteList: Array<OfficialWebsite> = [
    {
      id: 1,
      link: "http://zqkj.sanxiaxiu.com/html/pc/embroidery.html",
      name: "关于刺绣",
      imgUrl: require("@/assets/image/home/btn_0.jpg"),
      openLink: true,
    },
    {
      id: 2,
      link: "http://zqkj.sanxiaxiu.com/html/pc/brand.html",
      name: "关于品牌",
      imgUrl: require("@/assets/image/home/btn_1.jpg"),
      openLink: true,
    },
    {
      id: 3,
      link: "http://zqkj.sanxiaxiu.com/html/pc/culture.html",
      name: "品牌文化",
      imgUrl: require("@/assets/image/home/btn_2.jpg"),
      openLink: true,
    },
    {
      id: 4,
      link: "http://zqkj.sanxiaxiu.com/html/pc/product.html",
      name: "产品概述",
      imgUrl: require("@/assets/image/home/btn_3.jpg"),
      openLink: true,
    },
    {
      id: 5,
      link: "http://zqkj.sanxiaxiu.com/html/pc/entity.html",
      name: "实体掠影",
      imgUrl: require("@/assets/image/home/btn_4.jpg"),
      openLink: true,
    },
    {
      id: 6,
      link: "http://zqkj.sanxiaxiu.com/html/pc/cooperation.html",
      name: "商业合作",
      imgUrl: require("@/assets/image/home/btn_5.jpg"),
      openLink: true,
    },
  ];

  @Provide() tabs: Array<Tab> = [
    {
      id: 1,
      title: "传统工艺",
      name: "历史上的刺绣精品多为皇家贵族所专享，民间大师之作，也多出自富甲书香名门。精美的刺绣作品需要制作者有极高的针法技巧和艺术修养，制作过程还须磨炼心智，心无旁骛。",
      imgUrl: require("@/assets/image/home/cixiu_0.jpg"),
      icon: require("@/assets/image/home/process_icon.png"),
    },
    {
      id: 2,
      title: "题材创作",
      name: "一件好的刺绣作品首先取决于题材的选定和优化，选择适合刺绣表现的素材，并进行有针对性的二次创作，使之更能充分展示手工刺绣的质感和工艺的精妙。",
      imgUrl: require("@/assets/image/home/cixiu_1.jpg"),
      icon: "",
    },
    {
      id: 3,
      title: "选料上绷",
      name: "根据作品需要选择适合的底料，辅以绷布，并以适度的张力上绷。高档刺绣一般选择光泽温润、质感丝滑的真丝面料，与天然桑蚕丝完美搭配，作品质感细腻，色泽丰富沉稳，尽显内敛奢华。",
      imgUrl: require("@/assets/image/home/cixiu_2.jpg"),
      icon: "",
    },
    {
      id: 4,
      title: "分色劈线",
      name: "根据图案所需颜色及质感的要求，选配相应色泽的丝线，并将一根丝线劈分为1/2、1/4、1/8、1/16线等，最为精细的部位甚至还需1/32，这需要操作者具有极其敏锐的色感和长期的磨练。",
      imgUrl: require("@/assets/image/home/cixiu_3.jpg"),
      icon: "",
    },
    {
      id: 5,
      title: "针法变幻",
      name: "刺绣作为绘画的姊妹艺术，其成像原理更趋于素描的 “线” 成像，与素描不同的是，刺绣的线更细、更短、更密，且刺绣是用不同的针法去表现图案的轮廓、明暗、质感、色彩过渡等。",
      imgUrl: require("@/assets/image/home/cixiu_4.jpg"),
      icon: "",
    },
    {
      id: 6,
      title: "巧夺天工",
      name: "一副精美的手工刺绣作品，相比画作产生了全新的质感和视觉效果，巧夺天工的针法工艺和一针一线的人文情怀，无不让人赏心悦目，叹为观止。",
      imgUrl: require("@/assets/image/home/cixiu_5.jpg"),
      icon: "",
    },
    {
      id: 7,
      title: "画龙点睛",
      name: "作品最终完成之前，还须严格审视图案中最为精细和神韵妙趣之处，以相应规格的丝线和针法做最后的修饰和调整，力求点睛之笔，神来之作。",
      imgUrl: require("@/assets/image/home/cixiu_6.jpg"),
      icon: "",
    },
  ];
  @Provide() tabsIndex: number = 0;

  created() {
    const _this: any = this;

    _this.bannerChageFn();
  }

  mounted() {
    window.onscroll = function() {
      //为了保证兼容性，这里取两个值，哪个有值取哪一个
      //scrollTop就是触发滚轮事件时滚轮的高度
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      console.log("滚动距离" + scrollTop);
    }
    gsap.registerPlugin(ScrollTrigger);
    const _this: any = this;
    gsap.from(".animation01", {
      x: -60,
      duration: 2,
    })
    gsap.from(".animation03", {
      x: 60,
      duration: 2,
    })
    // new TweenMax(".animation03", 2, {
    //   x: -120,
    //   delay: 1,
    //   // ease: 'Bounce.easeOut',
    //   alpha: 1,
    // });
    gsap.fromTo(".animation02",
      {
        scale:0.9
      },
      {
        duration: 2,
        delay: 0.1,
        scale:1
      })
      gsap.to(".animation01-left", {
        x: 40,
        // autoAlpha: 0.6,// 透明度
        scrollTrigger: {
          trigger: ".animation01-left",
          start: 1031,
          end: 1070,
          scrub: 1,
        }
      });
      gsap.from(".animation01-right", {
        x: 20,
        // autoAlpha: 0.6,// 透明度
        scrollTrigger: {
          trigger: ".animation01-right",
          start: 1031,
          end: 1070,
          scrub: 1,
        }
      });
      gsap.from(".animation01-left1", {
        x: -20,
        // autoAlpha: 0.6,// 透明度
        scrollTrigger: {
          trigger: ".animation01-left1",
          // start: "300",
          // end: "top top",
          scrub: 1,
        }
      });
      gsap.from(".animation01-left2", {
        x: -20,
        // autoAlpha: 0.6,// 透明度
        scrollTrigger: {
          trigger: ".animation01-left2",
          // start: "300",
          // end: "top top",
          scrub: 1,
        }
      });
      gsap.from(".animation01-left3", {
        x: -20,
        // autoAlpha: 0.6,// 透明度
        scrollTrigger: {
          trigger: ".animation01-left3",
          // start: "300",
          // end: "top top",
          scrub: 1,
        }
      });
      gsap.from(".animation01-left4", {
        x: -20,
        // autoAlpha: 0.6,// 透明度
        scrollTrigger: {
          trigger: ".animation01-left4",
          // start: "300",
          // end: "top top",
          scrub: 1,
        }
      });
      gsap.from(".animation01-left5", {
        x: -20,
        // autoAlpha: 0.6,// 透明度
        scrollTrigger: {
          trigger: ".animation01-left5",
          // start: "300",
          // end: "top top",
          scrub: 1,
        }
      });
      gsap.from(".animation01-left6", {
        x: -20,
        // autoAlpha: 0.6,// 透明度
        scrollTrigger: {
          trigger: ".animation01-left6",
          // start: "300",
          // end: "top top",
          scrub: 1,
        }
      });

      

    window.addEventListener("scroll", _this.handleScroll);
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".home",
          start: "top top",
          end: "bottom bottom",
          scrub: 1,
        },
      })
      .fromTo(".bgx", { y: 0 }, { y: "-75%" }, 0);

      let quotes = new SplitText("#text1", { type: "chars" });
      gsap.from(quotes.chars, {
        scrollTrigger: {
          trigger: '#text1',
          toggleActions: "restart pause resume reverse",
          start: "top 80%",
        },
        duration: 0.6, 
        ease: "circ.out", 
        y: 180, 
        stagger: 0.02,
      });
    // let mySplitText = new SplitText("#text1", { type: "chars" });
    // let chars = mySplitText.chars;
    // TweenMax.set("#text", { perspective: 400 });
    // const quotes = document.querySelectorAll(".quote");
    // TweenMax.staggerFrom(chars, 0.8, {
    //   scrollTrigger: {
    //     trigger: quotes,
    //     toggleActions: "restart pause resume reverse",
    //     start: "top 50%",
    //   },
    //   duration: 0.6,
    //   scale: 4,
    //   autoAlpha: 0,
    //   rotationX: -180,
    //   transformOrigin: "100% 50%",
    //   ease: "back",
    //   stagger: 0.02,
    // });
    // //制作触发时间线
    // let demo = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: ".remarks",
    //     /*
    //      * ⚠ start: 表示动画开始执行节点
    //      * ⚠ end: 表示动画执行结束
    //      * 参数1: 表示当前这个元素的开始执行动画的起点
    //      *      【0：就是当前这个元素的顶端位置】
    //      *      【正数：当前这个元素根据顶端位置进行向下偏移】
    //      *      【负数：当前这个元素根据顶端位置进行向上偏移】
    //      *
    //      * 参数2: 表示当前滚动条的起点执行位置
    //      *      【0：浏览器最顶端】
    //      *      【正数：向下偏移】
    //      *      【负数：向上偏移】
    //      * 当两个位置重合时，动画开始执行/结束
    //      * 此处的位置可以使用像素单位，完全可以自定义
    //      */
    //     start: "0% 30%",
    //     end: "100% 10%",
    //     markers: true, //绘制开始位置和结束位置的线条
    //     scrub: true, //表示动画可以重复执行改成false表示只执行一次
    //     pin: true, //动画执行期间，动画元素不跟随页面进行滚动，动画执行结束后，恢复滚动
    //   },
    // });
    // // 时间点触发之后的操作
    // demo.to(".remarks", 2, {
    //   x: 10,
    //   opacity: 1,
    // });

   
  }

  /**
   * @Author HS
   * @Date 2021/7/7 2:06 下午
   * @Description: 监听页面滚动
   * @Params: null
   * @Return: null
   */
  handleScroll() {
    const _this: any = this;
    let scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop; // 滚动条偏移量
    if (scrollTop > 500 && scrollTop < 1000) {
      _this.gifShow = true;
      return;
    }
  }

  /**
   * @Author HS
   * @Date 2021/7/7 10:37 上午
   * @Description: banner切换
   * @Params: null
   * @Return: null
   */
  bannerChageFn() {
    const _this: any = this;
    let time = setTimeout(() => {
      if (_this.bannerIndex >= _this.bannerList.length - 1) {
        _this.bannerIndex = 0;
        clearTimeout(time);
        this.bannerChageFn();
        return;
      }
      _this.bannerIndex++;
      clearTimeout(time);
      this.bannerChageFn();
      return;
    }, 5000);
  }

  /**
   * @Author HS
   * @Date 2021/7/7 2:07 下午
   * @Description: 页面跳转
   * @Params: { string ：link - true } [跳转地址]
   * @Params: { boolean ：openLink - false } [是否打开新窗口]
   * @Return:
   */
  goRoute(link: string, openLink: boolean) {
    const _this: any = this;
    if (openLink) {
      window.open(link, "_blank");
      return;
    }
    _this.$base.goRouter(_this.$IndexConfig[link].path);
  }
}
